const metalglas = {
  head: 'Вікна',
  subhead:
    'прості та складні конструкції, збереження тепла, розробка конструкцій, впровадження  нових технологій',

  Content: [
    {
      id: 'metal_glas-01',
      content:
        'надаємо послуги з встановлювання вікон різних конструкцій та складності, розробляємо рішення для нетипових конструкцій ',
      details: 'windows',
    },
    {
      id: 'metal_glas-02',
      content:
        'ІТ безпека, управління подіями, резервне зберігання та відновлення даних ',
      details: 'doors',
    },
    // {
    //   id: 'data-03',
    //   content: 'Серверні рішення',
    //   details: 'servers',
    // },
    // {
    //   id: 'data-04',
    //   content: 'Рішення для аудіо-відео конференцій',
    //   details: 'avsystems',
    // },
  ],
};

export default metalglas;
