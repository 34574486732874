export default {
  head: 'Data transfering',
  subhead:
    'Providing system integration solutions in data transfering building direction  ',

  Content: [
    {
      id: 'data-01',
      content:
        'Data transfer medias. Network Architecture, Propjects creating,  Developing  of technical solutions, Mounting, Service',
      details: 'lan',
    },
    {
      id: 'data-02',
      content: 'IT security, Event routing, Backuping and data restoring ',
      details: 'itsecurity',
    },
  ],
};
