const lan = {
  head: 'Передавання даних - розрахунок,  постачання,  встановлення, сервіс ',
  pain: 'Мережі передачі даних - основа будь-якої структури – це саме те середовище, в якому відбувається передача всіх даних від систем безпеки, передається відео-аудіо-інформація, інтернет, дані, у тому числі корпоративні, службові та конфіденційні. Cпеціалісти нашої компанії мають багаторічний досвід у проектуванні, монтуванні та сервісній підтримці інформаційних систем та систем передавання даних різних категорій та складності. Ми працюємо на адміністративних , житлових та виробничих майданчиках, розробляємо та реалізуємо рішення в таких напрямах:',
  subhead: ['Надаємо в напрямках:', ' Надаємо в межах:'],
  solutions: [
    'Архітектура мереж (СКС, ЛВС, Серверні, ЦОДи), побудова нових та модернізація існуючих, створення єдиного інформаційного середовища на фізичному рівні:',
    'Єдиний простір передавання даних від різних систем та мереж, інтеграція в єдину структуру:',
    'Рішення для інтеграції з хмарними ресурсами',
  ],
  text1: [
    '- систем передачі голосу',
    '- даних',
    '- відео',
    '- систем безпеки',
    '- контролю доступу',
    '- відеоспостереження',
    'та інших систем на базі IP протоколу',
  ],
  text2: [
    '- віртуалізація систем',
    '- організація віддалених робочих процесів',
    '- інтеграція розподілених систем в єдину структуру',
  ],
  works: ['Проектна документація', 'Монтаж', 'Сервісне обслуговування'],
};

export default lan;
