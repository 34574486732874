const access = {
  head: 'системи контролю та керування доступом',
  subhead: ['Встановлюємо', 'В рамках', 'Вендори з якими ми працюємо'],
  pain: 'Компетенція наших спеціалістів охоплює системи контролю доступу від найпростіших брелкових аналогових систем і домофонів до систем з мультифакторною ідентифікацією, що входить до складу комплексів безпеки та ситем обліку робочого часу, що інтегруються з системами відеоспостереження та системами розпізнавання обличь',
  itemTitles: [
    'системи контролю доступу аналогові на брелоках та картах застосовується в більшості випадків',
    'домофони аналогові та IP',
    'термінальні мультифакторні автономні системи, контролю проходу та обліку робочого часу',
    'рішення для готелів - готельні замкові системи онлайн та офлайн',
    "Рішення для закритих та режимних об'єктів з підвищеними вимогами до безпеки ",
    'Рішення для комплексних систем з інтеграцією у загальну структуру безпеки (ситуаційні платформи)',
  ],
  linkTitle:
    ' - СКУД з ідентифікацією по венозній сітці або аналогічні системи',

  itemA: [
    ' - актуальні для обмеження доступу в офіси та житлові будинки (приватні та багатоквартирні)',
    ' - як спосіб захисту від несанкціонованого проходу у сховища, комори , запасні виходи адміністративних будівель (лікарень, поліклінік, магазинів, навчальних закладів)',
    ' - як інструмент контролю проходу та бліку співробітників для підприємств з невеликою кількістю персоналу',
  ],
  itemB: [
    " - встановлення домофонних панелей виклику в якості системи контролю в'їзду-виїзду",
    ' - контроль доступу в ліфтах',
    ' - інтеграція з мобільними додатками',
  ],
  itemC:
    'мультифакторний термінал – пристрій, який ідентифікує  особу кількома способами (наприклад картки та ідентифікацію по обличчю), може підключатися до системи контролю доступу як пристрій керування проходом, та фіксувати час, коли та чи інша особа увійшла або вийшла з підприємства. Ідеальне рішення для невеличких підприємств, які потребують функції контролю проходу та обліку робочого часу',
  itemCArray: [
    ' - підбираємо обладнання',
    ' - встановлюємо',
    ' - налаштовуємо систему',
  ],
  itemD:
    'готельні замкові системи – це окрема система гостьового доступу в гостьові номери готелю. Частіше за все -це карткові системи, але бувають інші (наприклад по мобільних додатках). Існують як онлайн (центральне керування з головного сервера готелю), так і офлайн (незалежні замкові системи для кожного номера, немає централізованого контролю та керування). Ключі (картки, або інші способи ідентифікації) прописуються при заселенні в готель, та здаються або ліквідуються при виселенні з номера',
  itemDArray: [
    '- проектуємо (як окремі системи так і в складі комплексного рішення всього готелю)',
    '- постачаємо обладнання',
    '- встановлюємо',
    '- налаштовуємо системи',
    '- робимо сервісне обслуговування',
  ],

  details2: [
    ' - СКУД з ідентифікацією по венозній сітці або аналогічні системи',
    ' - інші системи біометричної ідентифікації',
  ],
  works: [
    'Розробка проектної документації',
    'Вибір систем',
    'Інсталяція та налагодження',
    'Сервісна підтримка',
  ],
  links: {
    vein: 'https://drive.google.com/file/d/1897gLMYJD0sloUcYsGxeUu3wQHxUwkDB/view?usp=sharing',
  },
};
export default access;
