const lanDirections = {
  title: 'Архітектура мереж (СКС, ЛВС, Серверні, ЦОДи)',
  contentLAN: [
    'Iнженерна організація серверних та датацентрів:',
    'Структуровані кабельні системи:',
    'Локальні обчислювальні мережі:',
    'Інтеграція сторонніх систем у структуру передачі даних:',
  ],
  detailsDATA: [
    '- живлення',
    '- охолодження',
    '- безпека  (системи захисту від витоку інформації)',
  ],
  detailsSCS: [
    '- партнерство з провідними світовими вендорами',
    '- проектування',
    '- монтаж',
    '- тестування',
    '- сертифікація',
    '- гарантія',
  ],
  detailsLAN: [
    '- розробка архітектури',
    '- підбір обладнання',
    '- проектна документація',
    '- монтажні роботи',
    '- сервісна підтримка змонтованих систем',
  ],
  lanIntegration: [
    '- відеоспостереження',
    '- системи безпеки (охорона сигналізація, контроль доступу)',
    "- моніторинг та керування інженерними системами об'єкту",
    '- інші системи на базі IP протоколу',
  ],
};
export default lanDirections;
