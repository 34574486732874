const about = {
  head: 'Про нашу компанію',
  subhead: '',
  part1:
    'Компанія IV-Engineering – команда висококваліфікованих фахівців з багаторічним досвідом роботи в інжинірингових напрямках, таких як: ',
  directionList: [
    'інформаційні системи: мережі передачі даних, СКС, серверне обладнання та СЗД (системи зберігання даних)',
    'системи безпеки: протипожежний захист, системи сигналізації та контролю доступу різних рівнів, різні напрямки систем відеоспостереження, відеоаналітика, системи розпізнавання осіб',
    'різні напрямки, що реалізують технології альтернативної енергетики.',
  ],
  part2:
    'Нам довіряють вирішення найскладніших та нестандартних питань. Ми співпрацюємо з багатьма проектними та будівельними організаціями. Оскільки всі технології постійно змінюються і вдосконалюються, наші інженери та монтажники також регулярно проходять навчання та тренінги. Ми у постійному розвитку.',
  consulingTitle: 'В межах консалтингової діяльності:',
  consulting: [
    'Надаємо кваліфіковану незалежну експертну оцінку існуючого проекту та його бюджетну цінність',
    'При необхідності оптимізуємо або вдосконалюємо існуючі проекти',
    'Розробляємо нові проекти, пропонуємо наші рішення',
  ],
  consulting1:
    'Надаємо кваліфіковану незалежну експертну оцінку існуючого проекту та його бюджетну цінність',
  consulting2: 'При необхідності оптимізуємо або вдосконалюємо існуючі проекти',
  consulting3: 'Розробляємо нові проекти, пропонуємо наші рішення',
};

export default about;
